import * as Sentry from "@sentry/react";
import {
  ActiveRoutesApi,
  AisDataApi,
  AreaConstraintsV2Api,
  BaseCurveVesselPerformanceModelDataApi,
  Configuration,
  FleetSummaryApi,
  GuidanceJustificationsApi,
  MaintenanceEventsApi,
  MultiLegVoyagesApi,
  NavareaWarningsApi,
  OrganizationsApi,
  PortsApi,
  ReportingApi,
  RoutesApi,
  RouteSuggestionsApi,
  SeakeepingInputAlertApi,
  VesselPerformanceModelDataApi,
  VesselReportDataApi,
  VesselsApi,
  VoyagesApi,
  VesselAlertingEventsApi,
  WeatherApi,
  VoyageGuidanceApi,
  VesselGroupsApi,
  BankRoutesApi,
  ChartworldApi,
} from "@sofarocean/wayfinder-typescript-client";

import React from "react";

import { consoleAndSentryError } from "helpers/error-logging";
import { useCrystalGlobeAPIState } from "./use-crystal-globe-api-state";
import { defaultBase } from "./use-wayfinder-base";

/*
 * Custom error subclass that helps with legibility when viewing error logs
 * in Sentry.io
 */
export class WayfinderAPIError extends Error {
  constructor(message: string, private responseArg: Response) {
    super(message);
    this.name = "WayfinderAPIError";
  }
  public get response(): Response {
    return this.responseArg;
  }
}

type CrystalGlobeApiContextType = {
  ActiveRoutesApi: ActiveRoutesApi;
  RouteSuggestionsApi: RouteSuggestionsApi;
  VoyagesApi: VoyagesApi;
  VoyageGuidanceApi: VoyageGuidanceApi;
  VesselsApi: VesselsApi;
  VesselAlertingEventsApi: VesselAlertingEventsApi;
  VesselGroupsApi: VesselGroupsApi;
  OrganizationsApi: OrganizationsApi;
  AreaConstraintsV2Api: AreaConstraintsV2Api;
  AisDataApi: AisDataApi;
  RoutesApi: RoutesApi;
  MultiLegVoyagesApi: MultiLegVoyagesApi;
  PortsApi: PortsApi;
  FleetSummaryApi: FleetSummaryApi;
  VesselPerformanceModelDataApi: VesselPerformanceModelDataApi;
  BaseCurveVesselPerformanceModelDataApi: BaseCurveVesselPerformanceModelDataApi;
  VesselReportDataApi: VesselReportDataApi;
  GuidanceJustificationsApi: GuidanceJustificationsApi;
  ReportingApi: ReportingApi;
  WeatherApi: WeatherApi;
  SeakeepingInputAlertApi: SeakeepingInputAlertApi;
  NavareaWarningsApi: NavareaWarningsApi;
  MaintenanceEventsApi: MaintenanceEventsApi;
  BankRoutesApi: BankRoutesApi;
  ChartworldApi: ChartworldApi;
};

/** This wrapper function is used to ensure that `fetch` errors are reported by
 *  the swagger-codegen API clients in a format that is legible to Sentry.io.
 *  Without this, swagger-codegen just throws the `Response` object as an
 *  error, which Sentry does not recognize. We want to log a custom
 *  `WayfinderAPIError` instead for easy identification of API issues in Sentry.
 */
export const errorReportingFetch = (
  resourceLabel: string,
  initOverrides?: RequestInit,
  setError?: (e: Error, description: string) => void
) => async (
  input: RequestInfo,
  init?: RequestInit | undefined
): Promise<Response> => {
  const startTime = new Date().getTime();
  try {
    const res = await fetch(input, {
      ...init,
      ...initOverrides,
      headers: { ...init?.headers, ...initOverrides?.headers },
    });
    Sentry.setContext("Additional Info", {
      response: {
        url: res.url,
        statusText: res.statusText,
        status: res.status,
        body: JSON.stringify(res.body),
        method: init?.method,
      },
      fetchInfo: {
        body: init?.body,
        requestTimeElapsed: `${new Date().getTime() - startTime}ms`,
      },
    });
    if (!res.ok) {
      const errorResponse = await res.json();
      throw new WayfinderAPIError(
        `Could not fetch ${resourceLabel} from the Wayfinder API: status ${errorResponse.statusCode}, ${errorResponse.error}: ${errorResponse.message}`,
        res
      );
    }
    return res;
  } catch (error) {
    Sentry.setContext("Additional Info", {
      fetchInfo: {
        method: init?.method,
        body: init?.body,
        input,
        requestTimeElapsed: `${new Date().getTime() - startTime}ms`,
        resourceLabel,
      },
      navigator: {
        onLine: navigator.onLine,
        downlink: `${(navigator as any)?.connection?.downlink}Mbps`,
      },
    });
    // A fetch() promise will reject with a TypeError when a network error is encountered or CORS is misconfigured on the server-side
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
    consoleAndSentryError(error as Error, {
      tags: {
        failedToFetch: true,
        api: true,
      },
    });
    // If the error handler is passed in, use it to display the error message & return a rejected promise to avoid the useless
    // "interceptors" error message. Otherwise, throw the error to be caught by the caller
    if (setError) {
      setError(error as Error, `Could not fetch ${resourceLabel}`);
      return Promise.reject();
    }

    throw error;
  }
};

export const mockFetch = (
  resourceLabel: string,
  initOverrides?: RequestInit,
  setError?: (e: Error, description: string) => void
) => async (
  input: RequestInfo,
  init?: RequestInit | undefined
): Promise<Response> => {
  console.log(`API connection to ${resourceLabel} disabled.`);
  return new Promise(() => {});
};

export const getApis = (
  init?: RequestInit,
  url?: string,
  fetchFn: (
    resourceLabel: string,
    initOverrides?: RequestInit,
    setError?: (e: Error, description: string) => void
  ) => (
    input: RequestInfo,
    init?: RequestInit | undefined
  ) => Promise<Response> = errorReportingFetch,
  setError?: (e: Error, description: string) => void
) => {
  return {
    ActiveRoutesApi: new ActiveRoutesApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("ActiveRoutesApi", init, setError),
      })
    ),
    RouteSuggestionsApi: new RouteSuggestionsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("RouteSuggestionsApi", init, setError),
      })
    ),
    VoyagesApi: new VoyagesApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("VoyagesApi", init, setError),
      })
    ),
    VoyageGuidanceApi: new VoyageGuidanceApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("VoyageGuidanceApi", init),
      })
    ),
    OrganizationsApi: new OrganizationsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("OrganizationsApi", init, setError),
      })
    ),
    VesselsApi: new VesselsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("VesselsApi", init, setError),
      })
    ),
    VesselAlertingEventsApi: new VesselAlertingEventsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("VesselAlertingEventsApi", init, setError),
      })
    ),
    VesselGroupsApi: new VesselGroupsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("VesselGroupsApi", init, setError),
      })
    ),
    AreaConstraintsV2Api: new AreaConstraintsV2Api(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("AreaConstraintsApi", init, setError),
      })
    ),
    AisDataApi: new AisDataApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("AisDataApi", init, setError),
      })
    ),
    RoutesApi: new RoutesApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("RoutesApi", init, setError),
      })
    ),
    MultiLegVoyagesApi: new MultiLegVoyagesApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("MultiLegVoyagesApi", init, setError),
      })
    ),
    PortsApi: new PortsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("PortsApi", init, setError),
      })
    ),
    FleetSummaryApi: new FleetSummaryApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("FleetSummaryApi", init, setError),
      })
    ),
    VesselPerformanceModelDataApi: new VesselPerformanceModelDataApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("VesselPerformanceModelDataApi", init, setError),
      })
    ),
    BaseCurveVesselPerformanceModelDataApi: new BaseCurveVesselPerformanceModelDataApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn(
          "BaseCurveVesselPerformanceModelDataApi",
          init,
          setError
        ),
      })
    ),
    VesselReportDataApi: new VesselReportDataApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("VesselReportDataApi", init, setError),
      })
    ),
    GuidanceJustificationsApi: new GuidanceJustificationsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("GuidanceJustificationsApi", init, setError),
      })
    ),
    ChartworldApi: new ChartworldApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("ChartworldApi", init, setError),
      })
    ),
    ReportingApi: new ReportingApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("ReportingApi", init, setError),
      })
    ),
    WeatherApi: new WeatherApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("WeatherApi", init, setError),
      })
    ),
    SeakeepingInputAlertApi: new SeakeepingInputAlertApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("SeakeepingInputAlertApi", init, setError),
      })
    ),
    MaintenanceEventsApi: new MaintenanceEventsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("MaintenanceEventsApi", init, setError),
      })
    ),
    NavareaWarningsApi: new NavareaWarningsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("NavareaWarningsApi", init, setError),
      })
    ),
    BankRoutesApi: new BankRoutesApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("BankRoutesApi", init, setError),
      })
    ),
  };
};

const CrystalGlobeApiContextDefaults: CrystalGlobeApiContextType = getApis();

export const CrystalGlobeApiContext = React.createContext<CrystalGlobeApiContextType>(
  CrystalGlobeApiContextDefaults
);

export const CrystalGlobeApiProvider: React.FC<{}> = ({ children }) => {
  const apiValue = useCrystalGlobeAPIState();
  return (
    <CrystalGlobeApiContext.Provider value={apiValue}>
      {children}
    </CrystalGlobeApiContext.Provider>
  );
};
